import { useEffect } from 'react';
import Logo from '../assets/logo';
import '../styles/navbar.scss';


const NavBar = ({auth, companies, selected, set, admin})=>{
    

    useEffect(()=>{
        if (companies.length > 0 && !selected) {
        
            
            if (set) {
                set(companies[0]);  // Selecciona la primera empresa
            } else {
                console.error('set function is not defined');
            }
          }
    },[companies])

    const handleSelectChange = (e) => {
        const selectedCompanyId = parseInt(e.target.value);
        // Busca el objeto de la empresa que tenga el mismo id
        const selectedCompany = companies.find(company => company.id === selectedCompanyId);
        if (selectedCompany) {
           
            set(selectedCompany);  // Actualiza el estado con el objeto completo de la empresa
        } else {
            console.error('Company not found');
        }
       
    };

    
    return(
        <div className='navbar'>
            <div className="navbar-panel">
                <Logo/>
                {admin !=true && 
                    <div className="c-select">
                        <select name="company" id="company" onChange={handleSelectChange}>
                            {companies.map(company => (
                                <option 
                                key={company.id} 
                                value={company.id} 
                                selected={company.nombre === selected ? true : false}
                                >
                                {company.nombre}
                                </option>
                            ))}
                        </select>
                        <a href="/admin"></a>
                    </div>
                }
               
                
            </div>
            

            <div className="navbar-buttons">
                <h5>{auth.props.children[1]}</h5>
                {
                    auth.props.children[4]
                }
            </div>
        </div>
    )
}

export default NavBar;