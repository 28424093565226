import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useForm } from "react-hook-form";
import '../styles/login.scss';
import Logo from '../assets/logo';
import BannerIcon from '../assets/bannerIcon';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../auth';

const endpoint = process.env.REACT_APP_ENDPOINT;

const Login = () => {
    const { register: registerLogin, handleSubmit: handleSubmitLogin } = useForm();
    const { register: registerRecovery, handleSubmit: handleSubmitRecovery } = useForm();

    const navigate = useNavigate();
    const auth = useAuth();
    const location = useLocation();

    const [disabled, setDisabled] = useState(false);
    const [errors, setErrors] = useState([]);
    const [attempts, setAttempts] = useState(0);
    const [message, setMsg] = useState('');

    useEffect(()=>{
        if (auth.token) {
            
            if (auth.role == 1) {
                navigate('/super');
            } else if(auth.role == 0){
                navigate('/admin');
            }
            //actualizar ultima conexion 
            
        }else{
            const query = new URLSearchParams(location.search);
            const reset = query.get('reset');

            if (reset !== null) {
                document.querySelector('.btn-recovery').click();
                query.delete('reset');
                window.history.replaceState(null, '', `${location.pathname}?${query.toString()}`);
            }
        }
    },[]);
   

    const onSubmitLogin = async (data) => {
        setDisabled(true);
        if (attempts < 9) {
            try {
                const response = await axios.post(endpoint + 'login', {
                    email: data.email,
                    password: data.pass
                });

                let responseData = response.data;

                if (responseData.error) {
                    setErrors([{ 'msg': responseData.error }]);
                    console.log(responseData)   
                    let attemps = attempts + 1

                    if (responseData.attemps) {
                        setAttempts(responseData.attemps);
                        attemps = responseData.attemps
                    } else {
                        setAttempts(attempts + 1);
                        
                    }
                    if(responseData.error == 'Usuario bloqueado, restablezca su contraseña'){
                        attemps = 10
                    }
                    
                   
                    if (attemps > 4) {
                        console.log("Too many")
                        if(attemps < 9){
                            setDisabled(true);
                            setErrors(prevErrors => [...prevErrors, { msg: `${8 - attemps} intentos restantes. Espera 1 minuto para volverlo a intentar.` }]);
            
                            setTimeout(() => {
                                setDisabled(false);
                                
                            }, 10000);

                        }else{
                            setDisabled(false);
                            setErrors([{'msg': 'Tu usuario ha sido bloqueado debido a múltiples inicios de sesión fallidos. Para desbloquearla, ', 'button': 'restablece tu contraseña.'}]);
                        }
                        
                    }else{
                        setDisabled(false)
                    }
                    
                    
                } else {
                   
                    const { user, role, token } = responseData;
                    auth.signin(user, role, token, () => {
                        if (role === 1) {
                            navigate('/super');
                        } else {
                            navigate('/admin');
                        }
                    });
                }
            } catch (error) {
                setDisabled(false);
                setErrors(error.response ? [{ msg: error.response.data.message }] : [{ msg: 'Error desconocido' }]);
                setAttempts(attempts + 1);
            }
           
           
            
            
        } else {
            setDisabled(true);
            setErrors(['Demasiados intentos']);
        }
    };

    const handleRecovery = async (data) => {
        setDisabled(true);
        // Lógica para recuperar la contraseña
        try {
            const response = await axios.post(endpoint + 'forgot-password', {
                email: data.email
            });

            let responseData = response.data;

            if (responseData.error) {
                setErrors([{ 'msg': responseData.error }]);
                setDisabled(false);
            } else {
                setMsg(responseData.message)
                setDisabled(false);
            }
        } catch (error) {
            setDisabled(false);
            setErrors(error.response ? [{ msg: error.response.data.message }] : [{ msg: 'Error desconocido' }]);
            setAttempts(attempts + 1);
        }
    };

    return (
        <div className='login'>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Recuperar contraseña</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form className='login-pass' onSubmit={handleSubmitRecovery(handleRecovery)}>
                                <label htmlFor="email">Correo electrónico</label>
                                <input className='form-control' type='email' placeholder='Correo electrónico' {...registerRecovery("email", { required: true, maxLength: 128 })} />
                                {message}
                                <span>
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                                    <input className='btn btn-primary' disabled={disabled} type="submit" value='Recuperar' />
                                </span>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <form className='login-form' onSubmit={handleSubmitLogin(onSubmitLogin)}>
                <Logo />
                <h3>Iniciar sesión <br /> <small>para continuar</small></h3>
                <div className="c-input">
                    <label htmlFor='email'>Correo electrónico</label>
                    <input className="form-control" name='email' type='email' {...registerLogin("email", { required: true, maxLength: 128 })} />
                </div>
                
                <div className="c-input">
                    <label htmlFor='pass'>Contraseña</label>
                    <input className="form-control" type='password' name='pass' {...registerLogin("pass", { required: true, maxLength: 128 })} />
                </div>
                
                {
                    errors.map((error, index) => (
                        <div key={index} className='login-form-error'>
                            {error.msg}
                            {error.button && <a href='/?reset=1'>{error.button}</a>}
                        </div>
                    ))
                }
                
                <input className='btn btn-primary' disabled={disabled} type="submit" value='Ingresar' />

                <button type="button" className='btn btn-recovery' data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Olvidé mi contraseña
                </button>
            </form>

            <div className='login-info'>
                <h1>Bienvenido</h1>
                <img src="/banner.svg" alt="Banner" />
                <span>
                    <h3><strong>Soluciones legales <br />en recursos humanos</strong></h3>
                    <p>Nunca ha sido tan fácil cumplir <br /> Obligaciones fiscales</p>
                </span>
                
            </div>
        </div>
    );
};

export default Login;
