import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AuthStatus, useAuth } from '../auth';
import { DataGrid } from '@mui/x-data-grid';
import '../styles/super.scss';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavBar from './navbar';

const endpoint = process.env.REACT_APP_ENDPOINT;

const Super = () => {
    const { token } = useAuth();
    const [admins, setAdmins] = useState([]);
    const [newAdminName, setNewAdminName] = useState('');
    const [newAdminEmail, setNewAdminEmail] = useState('');
    const [newAdminPassword, setNewAdminPassword] = useState('');
    const [newAdminCompanies, setNewAdminCompanies] = useState(false);
    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const [error, setError] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [editAdminName, setEditAdminName] = useState('');
    const [editAdminEmail, setEditAdminEmail] = useState('');
    const [editAdminStatus, setEditAdminStatus] = useState(false);
    const [editAdminCompanies, setEditAdminCompanies] = useState(false); 
    const [fetch, setFetch] = useState(false);

    const status = AuthStatus();

    const fetchAdmins = async () => {
        try {
            const response = await axios.get(`${endpoint}superadmin/admins`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
           
            setAdmins(response.data);
        } catch (error) {
            setError('Error al obtener la lista de administradores');
        }
    };

    const createAdmin = async (e) => {
        console.log(token)
        e.target.classList.add('-disable')
        try {
            const response = await axios.post(`${endpoint}superadmin/admins`, {
                name: newAdminName,
                email: newAdminEmail,
                companies: newAdminCompanies,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setAdmins([...admins, response.data]);
            setNewAdminName('');
            setNewAdminEmail('');
            
            setNewAdminCompanies(1); 
            toast.success("Administrador creado");
            document.getElementById('addAdmin').click();
        } catch (error) {
            console.log(error)
            setError('Error al crear el administrador');
            toast.error("Error al crear el administrador");
            document.getElementById('addAdmin').click();
        }
        e.target.classList.remove('-disable')
    };

    const updateAdmin = async (id,e) => {
        setFetch(true);
        console.log('Companies'+editAdminCompanies )
        e.target.classList.add('-disable')
        try {
            const response = await axios.put(`${endpoint}superadmin/admins/${id}`, {
                name: editAdminName,
                email: editAdminEmail,
                status: editAdminStatus ? 'active' : 'block',
                companies: editAdminCompanies  
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const updatedAdmins = admins.map(admin => (admin.id === id ? response.data : admin));
            setAdmins(updatedAdmins);
            setSelectedAdmin(null);
            setFetch(false);
            toast.success("Administrador actualizado");
        } catch (error) {
            setError('Error al actualizar el administrador');
            setFetch(false);
            console.log(error)
            toast.error("Error al actualizar el administrador");
        }
        e.target.classList.remove('-disable')
    };

    const deleteAdmin = async (id,e) => {
        e.target.classList.add('-disable')
        try {
            await axios.delete(`${endpoint}superadmin/admins/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const updatedAdmins = admins.filter(admin => admin.id !== id);
            setAdmins(updatedAdmins);
            setSelectedAdmin(null);
            toast.success("Administrador eliminado");
        } catch (error) {
            setError('Error al eliminar el administrador');
            toast.error("Error al eliminar el administrador");
        }
        document.querySelector('.super-delete').click()
        e.target.classList.remove('-disable')
    };

    const dateFormatter = (param) => {
        const date = new Date(param);
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);

        if (!param) {
            return 'Nunca';
        } else if (date.toDateString() === today.toDateString()) {
            return `Hoy ${date.getHours()}:${date.getMinutes()}`;
        } else if (date.toDateString() === yesterday.toDateString()) {
            return 'Ayer';
        } else {
            const diffDays = Math.floor((today - date) / (1000 * 60 * 60 * 24));
            return `${diffDays} días`;
        }
    };

    useEffect(() => {
        fetchAdmins();
        console.log(admins)
    }, [token]);

    useEffect(() => {
        if (selectedAdmin) {
            setEditAdminName(selectedAdmin.name);
            setEditAdminEmail(selectedAdmin.email);
            setEditAdminStatus(selectedAdmin.status === 'active');
            setEditAdminCompanies(selectedAdmin.companies); 
            const offcanvasElement = document.getElementById('viewAdminOffcanvas');
            const offcanvasButton = document.getElementById('viewAdminButton');
            console.log(offcanvasButton)
            if (offcanvasElement && offcanvasButton) {
                offcanvasButton.click();
            }
        } else {
           
            const offcanvasElement = document.getElementById('viewAdminOffcanvas');
            const offcanvasButton = document.getElementById('viewAdminButton');
            console.log(offcanvasButton)
            if (offcanvasElement && offcanvasButton) {
                offcanvasButton.click();
            }
        }
    }, [selectedAdmin]);

    const filteredAdmins = admins.filter(admin =>
        admin.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        admin.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const columns = [
        { field: 'name', headerName: 'Nombre', width: 300 },
        { field: 'email', headerName: 'Correo electrónico', width: 300 },
        { 
            field: 'status', 
            headerName: 'Estatus', 
            width: 150,
            valueGetter: (value) => { return value === 'block' ? 'Suspendido' : 'Activo'; } 
        },
        { 
            field: 'companies', 
            headerName: 'Plan', 
            width: 150,
            valueGetter: (params) => params ? 'Multi' : 'Único' 
        }, 
        {
            field: 'last_connection',
            headerName: 'Última conexión',
            width: 150,
            valueGetter: (param) => dateFormatter(param)
        }
    ];

    return (
        <div className='super'>
            <NavBar auth={status} companies={[]} set={()=>{} }  admin={true} />
            <div className='super-wrapper'>
                <ToastContainer />
                <h2>Administradores</h2>
                {error && <p>{error}</p>}

                <div className="super-controls">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Buscar administradores..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <button className="btn btn-primary me-2" id='addAdmin' type="button" data-bs-toggle="offcanvas" data-bs-target="#createAdminOffcanvas" aria-controls="createAdminOffcanvas">
                        Agregar administrador
                    </button>
                </div>

                <div style={{ height: '80vh', width: '100%' }} className='super-table'>
                    <DataGrid
                        rows={filteredAdmins}
                        columns={columns}
                        sx={{ borderColor: 'white' }}
                        pageSize={5}
                        onRowClick={(row) => setSelectedAdmin(row.row)}
                        onRowDoubleClick={(row) => setSelectedAdmin(row.row)}
                        getCellClassName={(params) => {
                            if (params.field === 'status' && params.value === 'Activo') {
                                return 'active';
                            } else if (params.field === 'status') {
                                return 'block';
                            }
                            return '';
                        }}
                    />
                </div>

                <div className="offcanvas offcanvas-end" tabIndex="-1" id="createAdminOffcanvas" aria-labelledby="createAdminOffcanvasLabel">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="createAdminOffcanvasLabel">Agregar Administrador</h5>
                    </div>
                    <div className="offcanvas-body">
                        <div className="c-input">
                            <label htmlFor="addName">Nombre</label>
                            <input type="text" name='addName' value={newAdminName} onChange={(e) => setNewAdminName(e.target.value)} className="form-control" />
                        </div>
                        <div className="c-input">
                            <label htmlFor="addMail">Correo Electrónico</label>
                            <input type="email" name='addMail' value={newAdminEmail} onChange={(e) => setNewAdminEmail(e.target.value)} className="form-control" />
                        </div>


                        <div>
                            <label htmlFor="">Plan</label>
                            <div className="c-input -radio">
                                <input type="radio" id='unicaCompany' name='addCompanies' checked={!newAdminCompanies} onChange={(e) => setNewAdminCompanies(e.target.checked ? false: true)} className="form-check-input" />
                                <label htmlFor="unicaCompany">Empresa única</label>
                            </div>
                            
                            <div className="c-input -radio">
                                <input type="radio" id='multicompany' name='addCompanies' checked={newAdminCompanies} onChange={(e) => setNewAdminCompanies(e.target.checked ? true : false)} className="form-check-input" />
                                <label htmlFor="multicompany">Multiempresas</label>
                            </div>


                        </div>
                        
                        


                        <span>
                            <button className='btn btn-secondary' aria-label="Close" data-bs-dismiss="offcanvas">Cancelar</button>
                            <button className="btn btn-primary" onClick={(e)=>createAdmin(e)}>Agregar</button>
                        </span>
                    </div>
                </div>
                
                <button className="btn btn-primary" id='viewAdminButton' type="button" data-bs-toggle="offcanvas" data-bs-target="#viewAdminOffcanvas" aria-controls="viewAdminOffcanvas">Enable backdrop (default)</button>

                <div className="offcanvas offcanvas-end" tabIndex="-1" id="viewAdminOffcanvas" aria-labelledby="viewAdminOffcanvasLabel">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="viewAdminOffcanvasLabel">Detalles</h5>
                    </div>
                    {selectedAdmin &&
                        <div className="offcanvas-body">
                            <p><strong>Nombre</strong><br /> {selectedAdmin.name}</p>
                            <p><strong>Correo electrónico</strong><br /> {selectedAdmin.email}</p>
                            <p><strong>Estatus</strong><br /> {selectedAdmin.status === 'active' ? "Activo" : "Suspendido"}</p>
                            <p><strong>Plan</strong><br /> {selectedAdmin.companies ? 'Multi': 'Único'}</p> 
                            <p><strong>Última conexión</strong><br /> {selectedAdmin.last_connection ? dateFormatter(selectedAdmin.last_connection) : 'Nunca'}</p>

                            <hr />
                            <strong>Acciones</strong>
                            <span>
                                <button type="button" className="super-edit" data-bs-toggle="modal" data-bs-target="#editModal">
                                    Editar
                                </button>

                                <button type="button" className="super-delete" data-bs-toggle="modal" data-bs-target="#deleteModal">
                                    Eliminar
                                </button>
                            </span>
                        </div>
                    }
                </div>

                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        {selectedAdmin &&
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="deleteModalLabel">Confirmar</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    Estas a punto de eliminar el administrador <strong>{selectedAdmin.name}</strong> de forma definitiva
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-delete" onClick={(e) => deleteAdmin(selectedAdmin.id,e)}>Confirmar</button>
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                    
                

                <div className="modal fade" id="editModal" tabIndex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="editModalLabel">Editar Administrador</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <input type="text" value={editAdminName} onChange={(e) => setEditAdminName(e.target.value)} placeholder="Nombre" className="form-control" />
                                <input type="email" value={editAdminEmail} onChange={(e) => setEditAdminEmail(e.target.value)} placeholder="Correo electrónico" className="form-control" />
                                
                                <span>
                                    <label htmlFor="">Plan</label>
                                    <div className="c-input -radio"> 
                                        <input type="radio" id='unic' name='editCompanies' checked={!editAdminCompanies} onChange={(e) => setEditAdminCompanies(e.target.checked ? false : true)} className="form-check-input" />
                                        <label htmlFor="unic">Empresa única</label>
                                    </div>
                                    <div className="c-input -radio"> 
                                        <input type="radio" id='multi' name='editCompanies' checked={editAdminCompanies} onChange={(e) => setEditAdminCompanies(e.target.checked ? true: false)} className="form-check-input" />
                                        <label htmlFor="multi">Multiempresas</label>
                                    </div>
                                </span>
                                
                                <div className="form-check form-switch">
                                    
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="adminStatusToggle"
                                        checked={editAdminStatus}
                                        onChange={(e) => setEditAdminStatus(e.target.checked)}
                                    />
                                    <label className="form-check-label" htmlFor="adminStatusToggle">
                                        {editAdminStatus ? 'Activo' : 'Suspendido'}
                                    </label>
                                </div>
                                
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                                <button type="button" disabled={fetch} className="btn btn-primary" onClick={(e) => updateAdmin(selectedAdmin.id,e)}>Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Super;
