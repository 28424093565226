import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import '../styles/resetPassword.scss'; 
import Logo from '../assets/logo';

import { ToastContainer, toast } from 'react-toastify';


const endpoint = process.env.REACT_APP_ENDPOINT;

const ResetPassword = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [apiError, setApiError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const query   = new URLSearchParams(location.search);
    const token   = query.get('token');
    const email   = query.get('email');
    const welcome = query.get('new');

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        setApiError(null);
        
        // Ensure email and token are not null or empty before submitting
        if (!email || !token) {
            toast.error('El correo electrónico y el token son necesarios');
            setIsSubmitting(false);
            return;
        }
    
        try {
            const response = await axios.post(`${endpoint}reset-password`, {
                email,
                token,
                password: data.password,
                password_confirmation: data.password
            });
    
            if (response.status === 200) {
                
                toast.success('Contraseña restablecida correctamente');
                setTimeout(()=>{
                    navigate('/'); 
                },3000)
            }
        } catch (error) {
            // Log and show the error message
            console.log(error);
    
            // Check if the error is due to validation issues (status 422)
            if (error.response && error.response.status === 422) {
                // Handle specific validation errors
                toast.error('Hubo un problema con los datos enviados. Por favor verifica.');
                setApiError(error.response.data.error || 'Error desconocido');
            } else {
                // General error handling
                toast.error("Error al restablecer la contraseña: " + (error.message || 'Desconocido'));
                setApiError('Error desconocido');
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="reset-password">
            
            <a href="/"><Logo /></a>
            <form onSubmit={handleSubmit(onSubmit)}>
            
                <h3>{welcome == null ? "Restablecer Contraseña" : "Establecer Contraseña"} </h3>

                <label htmlFor="password">Nueva Contraseña</label>
                <input
                    type="password"
                    name="password"
                    {...register('password', { required: true, minLength: 8 })}
                />
                {errors.password && <span>La contraseña es requerida y debe tener al menos 8 caracteres</span>}

                <label htmlFor="password_confirmation">Confirmar Contraseña</label>
                <input
                    type="password"
                    name="password_confirmation"
                    {...register('password_confirmation', {
                        required: true,
                        validate: value => value === watch('password') || 'Las contraseñas no coinciden'
                    })}
                />
                {errors.password_confirmation && <span>{errors.password_confirmation.message}</span>}

                {apiError && <>
                    <div className="error-message">
                        {apiError}
                    </div>
                    <a href="/?reset=1">Generar nuevamente</a>
                </>}

                <button type="submit" disabled={isSubmitting}>Restablecer Contraseña</button>
            </form>
             <ToastContainer />
        </div>
    );
};

export default ResetPassword;